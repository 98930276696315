import React from 'react'
import VisaTypeListFilter from './visaTypeListFill/VisaTypeListFill'
import VisTypeList from './VisaTypeLists/VisTypetList'

function VisatypeList() {
  return (
    <>
      <VisaTypeListFilter/>
      <VisTypeList/>
    </>
  )
}

export default VisatypeList
