import React from 'react'

function PurchaseInwardList() {
    const data = {
        "invoices": [
            {
                "invNo": "HO212212P0106",
                "purchaseDate": "27 Dec 2021",
                "supplier": "GODREJ OPPLYONCEC DYV. (HDX002843) (3DY)",
                "productName": "GODREJ REF. RD EDGENEO 207C 33 THF OX WN -02004",
                "barcode": "52141501SD02004",
                "purQty": 1,
                "recoPurchaseQty": 16,
                "inwardQty": 15,
                "diff": 1
            },
            {
                "invNo": "HO212212P0112",
                "purchaseDate": "27 Dec 2021",
                "supplier": "GODREJ OPPLYONCEC DYV. (HDX002843) (3DY)",
                "productName": "GODREJ REF. RD EDGENEO 207C 33 THF OX WN -02004",
                "barcode": "52141501SD02004",
                "purQty": 15,
                "recoPurchaseQty": 16,
                "inwardQty": 15,
                "diff": 1
            },
            {
                "invNo": "-",
                "purchaseDate": "-",
                "supplier": "-",
                "productName": "-",
                "barcode": "-",
                "purQty": 16,
                "recoPurchaseQty": 32,
                "inwardQty": 30,
                "diff": 2
            }
        ]
    }

    return (
        <section>
            <div className="row m-4">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div className="tbl-caption">
                                    <h4 className="heading mb-0">Purchase & Inward
                                    </h4>
                                    <div>
                                        {/* <Link className="btn btn-primary btn-sm" to="/add-pending-transaction-report" role="button" aria-controls="offcanvasExample">+ PENDING TRANSACTION REPORT</Link> */}
                                        {/* <button type="button" className="btn btn-secondary btn-sm" >
                                + Invite Employee
                            </button> */}
                                    </div>
                                </div>
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <table id="table-to-xls" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                        <thead>
                                            <tr role="row">
                                                <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
                                                Inward No	
                                                </th>
                                                <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
                                                Purchase No
                                                </th>
                                                <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '203.45px' }}>
                                                ProductId
                                                </th>
                                                <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '156.475px' }}>
                                                Barcode </th>
                                                <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Email Address: activate to sort column ascending" style={{ width: '141.087px' }}>
                                                ProductName
                                                </th>
                                                <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '161.675px' }}>
                                                InwardQty</th>


                                                <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '161.675px' }}>
                                                PIQty
                                                </th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.invoices.map((invoice, index) => (
                                                <tr role="row" key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
                                                    <td className="sorting_1"><span>{index + 1}</span></td>
                                                    <td>{invoice.invNo}</td>
                                                    <td>{invoice.purchaseDate}</td>
                                                    <td>{invoice.supplier}</td>
                                                    <td>{invoice.productName}</td>
                                                    <td>{invoice.barcode}</td>
                                                    <td>{invoice.purQty}</td>
                                                    <td>{invoice.recoPurchaseQty}</td>
                                                    
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="dataTables_info" id="empoloyees-tblwrapper_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div><div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate"><a className="paginate_button previous disabled" aria-controls="empoloyees-tblwrapper" data-dt-idx={0} tabIndex={0} id="empoloyees-tblwrapper_previous"><i className="fa-solid fa-angle-left" /></a><span><a className="paginate_button current" aria-controls="empoloyees-tblwrapper" data-dt-idx={1} tabIndex={0}>1</a><a className="paginate_button " aria-controls="empoloyees-tblwrapper" data-dt-idx={2} tabIndex={0}>2</a></span><a className="paginate_button next" aria-controls="empoloyees-tblwrapper" data-dt-idx={3} tabIndex={0} id="empoloyees-tblwrapper_next"><i className="fa-solid fa-angle-right" /></a></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section>
    )
}

export default PurchaseInwardList
