import { PlayNif50 } from "../../components/playNif50/PlayNif50"


function PlayNif50Page() {
    return (
        <>
            <PlayNif50 />
        </>
    )
}
export default PlayNif50Page