import PriceMaster from "../../../components/priceList/priceMaster/PriceMaster"


const PriceMasterPage = () => {
    return (
        <>
            <PriceMaster />
        </>
    )
}

export default PriceMasterPage