import { ExpensePurpose } from "../../../components/farmExpense/expensePurpose/ExpensePurpose"


function ExpensePurposePage() {
    return (
        <>
            <ExpensePurpose />
        </>
    )
}
export default ExpensePurposePage