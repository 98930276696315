import VehicleLoadingSheet from "../../../components/logistics/vehicleLoadingSheet/VehicleLoadingSheet"




const VehicleLoadingSheetPage = () => {
    return (
        <>
            <VehicleLoadingSheet />
        </>
    )
}

export default VehicleLoadingSheetPage