import React from 'react'
import CompanyTypeMaster from '../../components/topNavigationComp/masters/companyTypeMaster/CompanyTypeMaster'

function CompanyTypeMasters() {
  return (
    <>
      <CompanyTypeMaster/>
    </>
  )
}

export default CompanyTypeMasters
