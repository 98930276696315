import JobworkOutRecipet from "../../../../components/transaction/jobworkOut/jobworkOutRecipet/JobworkOutRecipet"


const JobworkOutRecipetPage = () => {
    return (
        <>
            <JobworkOutRecipet />
        </>
    )
}

export default JobworkOutRecipetPage