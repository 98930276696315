import BankPayment from "../../../../components/transaction/quickEntry/bankPayment/BankPayment"


const BankPaymenttPage = ({ heading }) => {
    return (
        <>
            <BankPayment heading={heading} />
        </>
    )
}

export default BankPaymenttPage