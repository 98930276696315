import AllDocument from "../../../components/documentManagement/allDocument/AllDocument"



const AllDocumentPage = () => {
    return (
        <>
            <AllDocument />
        </>
    )
}

export default AllDocumentPage