import PurchaseOrderFromQuotation from "../../../../components/transaction/order/purchaseOrderFromQuotation/PurchaseOrderFromQuotation"


const PurchaseOrderFromQuotationPage = () => {
    return (
        <>
            <PurchaseOrderFromQuotation />
        </>
    )
}

export default PurchaseOrderFromQuotationPage