import DNEntrywithStock from "../../../../components/transaction/gst/DNEntrywithStock/DNEntrywithStock"



const DNEntrywithStockPage = () => {
    return (
        <>
            <DNEntrywithStock />
        </>
    )
}

export default DNEntrywithStockPage