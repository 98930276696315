import GSTExpenseEntry from "../../../../components/transaction/gst/GSTExpenseEntry/GSTExpenseEntry"


export const GSTExpenseEntryPage = () => {
    return (
        <>
            <GSTExpenseEntry />
        </>
    )
}
