import PurchaseQuatation from "../../../../../components/transaction/Quatations/purchaseQuatation/PurchaseQuatation"



const PurchaseQuatationPage = () => {
    return (
        <>
            <PurchaseQuatation />
        </>
    )
}

export default PurchaseQuatationPage