import DocumentCategories from "../../../components/documentManagement/documentCategories/DocumentCategories"


const DocumentCategoriesPage = () => {
    return (
        <>
            <DocumentCategories />
        </>
    )
}

export default DocumentCategoriesPage