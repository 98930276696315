import Quatation from "../../../../../components/transaction/Quatations/Quatation/Quatation"

function QuatationPage() {

    return (
        <>
            <Quatation />
        </>
    )
}
export default QuatationPage