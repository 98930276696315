import LoginHistory from "../../../components/reports/loginHistory/LoginHistory"


function LoginHistoryPage() {
    return (
        <>

            <LoginHistory />
        </>
    )
}
export default LoginHistoryPage