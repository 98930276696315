import React from 'react'
import AddNewTaskFromTemplateComp from '../../components/addNewTaskFromTemplateComp/AddNewTaskFromTemplateComp'

function AddNewTaskFromTemplatePage() {
  return (
    <>
    <AddNewTaskFromTemplateComp />
    </>
  )
}

export default AddNewTaskFromTemplatePage