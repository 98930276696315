import TalukaWiseDealerList from "../../../../components/electronicsReports/accountReport/talukaWiseDealerList/TalukaWiseDealerList"


const TalukaWiseDealerListPage = () => {
    return (
        <>
            <TalukaWiseDealerList />
        </>
    )
}

export default TalukaWiseDealerListPage