
import RateSheert from "../../../components/priceList/rateSheert/RateSheert"



const RateSheetPage = () => {
    return (
        <>
            <RateSheert />
        </>
    )
}

export default RateSheetPage