import GetWiseReportset from "../../../components/farmsReports/getWiseReportset/GetWiseReportset"


export const GetWiseReportsetPage = () => {
    return (
        <>
            <GetWiseReportset />
        </>
    )
}
