import { Payments } from "../../components/payments/Payments"


function PaymentsPage() {
    return (
        <>
            <Payments />
        </>
    )
}
export default PaymentsPage