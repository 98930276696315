import { VaccineMonitor } from "../../../components/cowMonitor/vaccineMonitor/VaccineMonitor"


function VaccineMonitorPage() {
    return (
        <>
            <VaccineMonitor />
        </>
    )
}
export default VaccineMonitorPage