import { NotificationHistory } from "../../../components/reports/notificationHistory/NotificationHistory"


function NotificationHistoryPage() {
    return (
        <>
            <NotificationHistory />
        </>
    )
}
export default NotificationHistoryPage