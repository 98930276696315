import React from 'react'
import ContractsComp from '../../components/contractsComp/ContractsComp'

function ContractsPage() {
    return (
        <>
            <ContractsComp />
        </>
    )
}

export default ContractsPage