import RcmEntry from "../../../../components/transaction/gst/RcmEntry/RcmEntry"

function RcmEntryPage() {

    return (
        <>
            <RcmEntry />
        </>
    )
}
export default RcmEntryPage