import SalseOrder from "../../../../components/transaction/order/salseOrder/SalseOrder"


function SalesOrderPage() {
    return (
        <>
            <SalseOrder />
        </>
    )
}
export default SalesOrderPage