import ArchivedDocumentss from "../../../components/documentManagement/archivedDocumentss/ArchivedDocumentss"

const DocumentsArchivePage = () => {
    return (
        <>
            <ArchivedDocumentss />
        </>
    )
}

export default DocumentsArchivePage