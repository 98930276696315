import RoutineMonitor from "../../../components/cowMonitor/routineMonitor/RoutineMonitor"


function RoutineMonitorPage() {
    return (
        <>
            <RoutineMonitor />
        </>
    )
}
export default RoutineMonitorPage