import React from 'react'
import BranchMaster from '../../components/branchMaster/BranchMaster'

function BranchMasterPage() {
    return (
        <>
            <BranchMaster />
        </>
    )
}

export default BranchMasterPage