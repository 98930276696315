import { OrderFromQuation } from "../../../../components/transaction/order/orderFromQuation/OrderFromQuation"



const OrderFromQuationPage = () => {
    return (
        <>
            <OrderFromQuation />
        </>
    )
}

export default OrderFromQuationPage