import { WhoOnline } from "../../../components/Analytics/WhoOnline/WhoOnline"


function WhoOnlinePage() {
    return (
        <>
            <WhoOnline />
        </>
    )
}
export default WhoOnlinePage