import { MonitoringServices } from "../../../components/catalog/monitoringServices/MonitoringServices"


const MonitoringServicesPage = () => {
    return (
        <>

            <MonitoringServices />
        </>
    )
}

export default MonitoringServicesPage