import React from 'react'
import InterBankStockTransferComp from '../../components/interBankStockTransferComp/InterBankStockTransferComp'

function InterBankStockTransferPage() {
    return (
        <>
            <InterBankStockTransferComp />
        </>
    )
}

export default InterBankStockTransferPage