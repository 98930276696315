import React from 'react'
import TasksOverviewComp from '../../components/tasksOverviewComp/TasksOverviewComp'

function TasksOverviewPage() {
  return (
    <>
    <TasksOverviewComp />
    </>
  )
}

export default TasksOverviewPage