import { GSTIncome } from "../../../../components/transaction/gst/GSTIncome/GSTIncome"


const GSTIncomePage = () => {
    return (
        <>
            <GSTIncome />
        </>
    )
}

export default GSTIncomePage