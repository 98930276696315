import Process from "../../components/process/Process"


const ProcessPage = () => {
    return (
        <>
            <Process />
        </>
    )
}

export default ProcessPage