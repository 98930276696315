import LeaveApplication from "../../../components/transaction/leaveApplication/LeaveApplication"


export const LeaveApplicationPage = () => {
    return (
        <>
            <LeaveApplication />
        </>
    )
}
