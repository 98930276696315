import React from 'react'

function WaBlockFilter() {
    return (
        <>
            
                <div className='card ' style={{height:'min-content'}}>
                    <div className='row'>
                        <div className='col-xl-6'><input className='form-control' placeholder='search' type='search' /></div>
                    </div>
                </div>
                
            
        </>

    )
}

export default WaBlockFilter
