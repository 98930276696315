import React from 'react'
import TasksListComp from '../../components/tasksListComp/TasksListComp'

function TasksListPage({style}) {
  return (
    <>
    <TasksListComp style={style}/>
    </>
  )
}

export default TasksListPage