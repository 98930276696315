import React from 'react'
import FlightRequest from '../../components/flightComp/flightRequest/FlightRequest'

function FlightRequestPages() {
    return (
        <>
            <FlightRequest />
        </>
    )
}

export default FlightRequestPages