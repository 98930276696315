import RecentHistory from "../../../components/Analytics/RecentHistory/RecentHistory"


function RecentHistoryPage() {
    return (
        <>
            <RecentHistory />
        </>
    )
}
export default RecentHistoryPage