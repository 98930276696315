import GSTJournal from "../../../../components/transaction/gst/GSTJournal/GSTJournal"

const GSTJournalPage = () => {
    return (
        <>
            <GSTJournal />
        </>
    )
}

export default GSTJournalPage