import { FoodUnit } from "../../../components/catalog/foodUnit/FoodUnit"


const FoodUnitPage = () => {
    return (
        <>
            <FoodUnit />
        </>
    )
}

export default FoodUnitPage