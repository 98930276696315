import DocumentsAuditTrail from "../../../components/documentManagement/documentsAuditTrail/DocumentsAuditTrail"

const DocumentsAuditTrailPage = () => {
    return (
        <>
            <DocumentsAuditTrail />
        </>
    )
}

export default DocumentsAuditTrailPage