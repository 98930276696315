import React from 'react'
import AddNewContractComp from '../../components/addNewContractComp/AddNewContractComp'

function AddNewContractPage() {
    return (
        <>
            <AddNewContractComp />
        </>
    )
}

export default AddNewContractPage