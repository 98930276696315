import React from 'react'
import AddProposalComp from '../../components/addProposalComp/AddProposalComp'

function AddProposalPage() {
    return (
        <>
            <AddProposalComp />
        </>
    )
}

export default AddProposalPage
