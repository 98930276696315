import React from 'react'
import SaleRegisterDetails from './saleRegeisterDetails/SaleRegisterDetails'

function SalesRegisters() {
    return (
        <>
            <SaleRegisterDetails />
        </>
    )
}

export default SalesRegisters