import React from 'react'
import ProcessGroupMaster from '../../components/processgroupmaster/ProcessGroupMaster'

function Processgropmasterspage() {
    return (
        <>
            <ProcessGroupMaster />
        </>
    )
}

export default Processgropmasterspage