import { SaleList } from "../../../components/cowSale/saleList/SaleList"


function SaleListPage() {
    return (
        <>
            <SaleList />
        </>
    )
}
export default SaleListPage