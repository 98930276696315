import { SaleDueCollectionn } from "../../../components/cowSale/saleDueCollection/SaleDueCollection"



function SaleDueCollectionnPage() {
    return (
        <>
            <SaleDueCollectionn />
        </>
    )
}
export default SaleDueCollectionnPage