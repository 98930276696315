import FoodItem from "../../../components/catalog/foodItem/FoodItem"


const FoodItemPage = () => {
    return (
        <>
            <FoodItem />
        </>
    )
}

export default FoodItemPage