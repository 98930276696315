import JobworkOutOrder from "../../../../components/transaction/jobworkOut/jobworkOutOrder/JobworkOutOrder"



const JobworkOutOrderPage = () => {
    return (
        <>
            <JobworkOutOrder />
        </>
    )
}

export default JobworkOutOrderPage