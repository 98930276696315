import ProductionEntry from "../../../components/transaction/productionEntry/ProductionEntry"


const ProductionEntryPage = () => {
    return (
        <>
            <ProductionEntry />
        </>
    )
}

export default ProductionEntryPage