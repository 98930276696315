import React from 'react'
import BranchMasterList from './branchMasterList/BranchMasterList'

function BranchMaster() {
    return (
        <>
            <BranchMasterList />
        </>
    )
}

export default BranchMaster