import JobworkOutIssue from "../../../../components/transaction/jobworkOut/jobworkOutIssue/JobworkOutIssue"



const JobworkOutIssuePage = () => {
    return (
        <>
            <JobworkOutIssue />
        </>
    )
}

export default JobworkOutIssuePage