import GSTPayment from "../../../../components/transaction/gst/GSTPayment/GSTPayment"


const GstPaymentPage = () => {
    return (
        <>
            <GSTPayment />
        </>
    )
}

export default GstPaymentPage