import AnimalType from "../../../components/catalog/animalType/AnimalType"


function AnimalTypePage() {
    return (
        <>
            <AnimalType />
        </>
    )
}
export default AnimalTypePage
