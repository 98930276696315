import StockJournalOutward from "../../../components/logistics/stockJournalOutward/StockJournalOutward"

const StockJournalOutwardPage = () => {
    return (
        <>
            <StockJournalOutward />
        </>
    )
}

export default StockJournalOutwardPage