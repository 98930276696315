import ExpenseList from "../../../components/farmExpense/expenseList/ExpenseList"


function ExpenseListPage({style}) {
    return (
        <>
            <ExpenseList style={style} />
        </>
    )
}
export default ExpenseListPage